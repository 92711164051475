class SliderMini extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.initSwiper();
    }

    initSwiper() {
        let swiperOptions = {
            slidesPerView: 'auto',
            spaceBetween: 24,
            freeMode: true,
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 64,
                    freeMode: false
                },
                576: {
                    slidesPerView: 4,
                    spaceBetween: 64,
                    freeMode: false
                },
                993: {
                    slidesPerView: 4,
                    spaceBetween: 64,
                    freeMode: false
                }
            },
            navigation: {
                nextEl: this.querySelector('.js_btn-next'),
                prevEl: this.querySelector('.js_btn-prev'),
            }
            // Add more Swiper options here if needed
        };

        if(this.hasAttribute('data-config')) {
        	try {
        		const thisOptions = JSON.parse(this.getAttribute('data-config'));
        		swiperOptions = mergeObjects(swiperOptions, thisOptions);
        		
        	} catch(e) {
            console.error(e.name);
            console.error(e.message)
          }
        }

        const swiperContainer = this.querySelector('.swiper');
        if (swiperContainer) {
            new Swiper(swiperContainer, swiperOptions);
        } else {
            console.error("Swiper container not found within the component.");
        }

        function mergeObjects(target, source = {}) {
				  const result = { ...target };

				  for (const key in source) {
				    if (source.hasOwnProperty(key)) {
				      if (typeof source[key] === 'object' && source[key] !== null && !Array.isArray(source[key])) {
				        result[key] = mergeObjects(result[key] || {}, source[key]);
				      } else {
				        result[key] = source[key];
				      }
				    }
				  }

				  return result;
				}
    }
}

customElements.define('slider-mini', SliderMini);
